import { RateStars } from '../rate-stars/rate-stars';
import { SvgIcon } from '../svg-icon/svg-icon';
import {
  Badge,
  Buttons,
  BuyBtn,
  Container,
  Content,
  Control,
  FavBtn,
  ImgWrapper,
  Input,
  Label,
  Main,
  MainPrice,
  OldPrice,
  Price,
  ContactsBox,
  RateStarsDesktop,
  RateStarsMobile,
  LabelNotAvailable,
  MainBox,
  SubTitle,
  Title,
  ZWLPrice,
  LabelInStock,
  LabelOutStock,
  TitleTradesView
} from './p-card-styled';
import React, { useState } from 'react';
import { ApproveSeller } from '../approve-seller/approve-seller';
import { Link } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';
import { addProductToCart} from '../../redux/slices/product/productSlice';
import { useDispatch , useSelector} from 'react-redux';
import { Button } from 'components/categories-card/categories-card-styled';
import { formatMoney } from 'utils';

export const PCardGroceriesView = ({ product, handleFavBtnClick, isFav, currentViewType }) => {
  const dispatch = useDispatch();
  const { productsInCart } = useSelector((state) => state.product);
  const productUrl = getRouteById('product', { slug: product.slug });
  const [counter, setCounter] = useState(1);

  const handleAddToCart = () => {
          // dispatch(addProductToCart({ product, quantity: 1, variation: { color: 'red', size: 'M' } }));
          dispatch(addProductToCart({ product, quantity: counter, variation: { } }))
  };
  
  const handleInputChange = (e) => {
    const value = Math.max(1, +e.target.value);
    setCounter(value);
  };
      
  const isProductInCart = productsInCart?.some(item => item.product.id === product.id);

  return (
    <Container currentViewType={currentViewType}>
      <Link to={productUrl}>
        <ImgWrapper currentViewType={currentViewType}>
          <ApproveSeller />
          <img src={product?.image?.url} alt={product.title} />
          <FavBtn onClick={handleFavBtnClick} isFav={isFav}>
            <SvgIcon name={'heart-2'} />
          </FavBtn>
          {product.availability.status ? (
            <LabelInStock>In Stock</LabelInStock>
          ) : (
            <LabelOutStock>Out stock</LabelOutStock>
          )}
          {product.delivery ? (
            <Label>Delivery Available</Label>
          ) : (
            <LabelNotAvailable>Delivery not available</LabelNotAvailable>
          )}
          </ImgWrapper>
      </Link>
      <Main currentViewType={currentViewType}>
        <MainBox>
          <RateStarsDesktop>
            {product.rating && (
              <RateStars rate={product.rating} reviews={product.totalReviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Link to={productUrl}>
        <TitleTradesView subtitle={product.subtitle}>{product.title}</TitleTradesView>
          {product.description && (
              <SubTitle>
                {product.description.length > 80
                  ? `${product.description.substring(0, 80)}...`
                  : product.description}
              </SubTitle>
            )}
        </Link>
        <Content>
          <Price currentViewType={currentViewType}>
            

            {
              product.price.sellingPrice?.amount ? <>
                {product.price.normalPrice?.amount && <OldPrice>{formatMoney(product.price.normalPrice.amount)}</OldPrice>}
                <MainPrice>{formatMoney(product.price.sellingPrice?.amount)}</MainPrice>
              </> :
                <MainPrice>{formatMoney(product.price.normalPrice?.amount)}</MainPrice>
            }
            {/* <ZWLPrice>ZWL 200</ZWLPrice> */}
          </Price>
          <Control>
            <RateStarsMobile>
              {product.rating && (
                <RateStars rate={product.rating} reviews={product.totalReviews} color={'#388638'} />
              )}
            </RateStarsMobile>
            <Buttons>
              {isProductInCart ? 
                 <Link to="/cart">
                      <Button>
                        View
                     </Button>
                </Link>:
                <>
                   <Input type={'number'} value={counter} onChange={handleInputChange} />
                  <BuyBtn onClick={handleAddToCart}>
                    <SvgIcon name='cart' />
                  </BuyBtn>
                </>
                }
            </Buttons>
          </Control>
        </Content>
        {product.badge && <Badge>{product.badge}</Badge>}
      </Main>
    </Container>
  );
};
