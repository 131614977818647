import { useEffect, useState } from 'react';
import { SectionHeader } from '../../../../components/section-header/section-header';
import {
  CardWrapper,
  InputWrapper,
  RecentUpdatesStyled,
  SortWrapper,
  Top
} from './recent-updates-styled';
import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { SelectWithLabel } from '../../../../components/select-with-label/select-with-label';
import { BlogCard } from '../blog-card/blog-card';

export const RecentUpdates = ({ articles }) => {
  const [loading, setLoading] = useState(true); // State to track loading

  return (
    <RecentUpdatesStyled>
      <SectionHeader link={'updates'} title={'Latest News & Updates'} />
      <Top>
        {/* Optional filters/search/sort */}
        {/* 
          <InputWrapper>
            <InputWithLabel height={40} placeholder={'Enter what you are looking for...'} isLabel={false} />
          </InputWrapper>
          <SortWrapper>
            <SelectWithLabel label={'Sort by:'}></SelectWithLabel>
          </SortWrapper>
        */}
      </Top>
      <CardWrapper>
      {articles?.length > 0 ? (
       articles.map((article, i) => (
       <BlogCard blog={article} key={i} />
       ))
       ) : (
       <div>No articles found</div> 
       )}
      </CardWrapper>    
    </RecentUpdatesStyled>
  );
};
