import { useRef, useState, useEffect } from 'react';
import { CategoriesWrapper, CategoryLink } from './categories-styled';
import { useDispatch } from 'react-redux';
import { getCategories } from '../../../../redux/slices/category/categorySlice';
import { result } from 'lodash';
export const LINKS = [
    {
        title: 'Books & Stationary',
        link: '#'
    },
    {
        title: 'Clothing & Apparel',
        link: '#'
    },
    {
        title: 'Construction & Hardware Supplies',
        link: '#'
    },
    {
        title: 'Electronic Goods',
        link: '#'
    },
    {
        title: 'Food & Groceries',
        link: '#'
    },
    {
        title: 'Health & Beauty',
        link: '#'
    },
    {
        title: 'Home & Garden',
        link: '#'
    },
    {
        title: 'Motor Spare & Accessories',
        link: '#'
    },
    {
        title: 'Motor Vehicles',
        link: '#'
    },
    {
        title: 'Real Estate',
        link: '#'
    },
    {
        title: 'Farming Supplies',
        link: '#'
    },
    {
        title: 'Industrial and Office Supplies',
        link: '#'
    },
    {
        title: 'Motor Vehicles',
        link: '#'
    }
];

const Categories = ({ isOpen, onCategorySelect }) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [mini, setMini] = useState(true);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getParentCategories = async () => {
            await dispatch(getCategories({ mini })).then((result) => {
                // console.log('Header categories', result);
                setCategories(result.payload.data);
                setLoading(false);
            });
        };
        getParentCategories();
    }, [page, perPage, mini]);

    const ref = useRef(null);
    const [wrapperHeight, setWrapperHeight] = useState(0);
    useEffect(() => {
        if (ref && ref.current) {
            setWrapperHeight((ref.current.clientHeight + 14.5) * LINKS.length + 40);
        }
    }, [ref]);
    return (
        <CategoriesWrapper height={wrapperHeight} isOpen={isOpen}>
            {loading
                ? LINKS.map((data, index) => {
                      return (
                          <CategoryLink
                              ref={ref}
                              href={data.link}
                              key={index}
                              onClick={() => onCategorySelect(data.name)}
                          >
                              {data.title}
                          </CategoryLink>
                      );
                  })
                : categories.map((data, index) => {
                      return (
                          <CategoryLink
                              ref={ref}
                              href={data.link}
                              key={index}
                              onClick={() => onCategorySelect(data.name)}
                          >
                              {data.name}
                          </CategoryLink>
                      );
                  })}
        </CategoriesWrapper>
    );
};

export default Categories;
