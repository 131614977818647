import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { buildQueryParams } from "../../../utils";

const categoriesURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_CATEGORIES_API_URL;
const attributesURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ATTRIBUTES_API_URL;
const popularCategoriesURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_POPULAR_CATEGORIES_API_URL;
const apiBase = process.env.REACT_APP_API_BASE_ENDPOINT;

const initialState = {
    categories: [],
    attributes: [],
    popularCategories: [],
    status: 'idle',
    error: null,
    category: null,
    products: null,
    productsPagination: null,
    categoryLoading: false
};

export const getCategories = createAsyncThunk(
    'categories/getCategories',
    async ({ page, per_page, mini }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${categoriesURL}?mini=${mini}`, {
                params: { page, per_page, mini },
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.data.message === 'success') {
                return response.data;
            }

            return rejectWithValue('Failed to fetch categories');
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Network error');
        }
    }
);

export const getCategoryAndProducts = createAsyncThunk(
    'categories/getCategoryAndProducts',
    async (params, { rejectWithValue }) => {
        const { slug, name, id, page = 1, per_page = 12 } = params;
        try {
            const response = await axios.get(
                `${apiBase}/categories?${buildQueryParams({ slug, name, id, page, per_page })}`,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            if (response.data.message === 'success') {
                return response.data;
            }
            return rejectWithValue(`Failed to fetch category with slug: ${slug}`);
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Network error');
        }
    }
);

export const getAttributes = createAsyncThunk('categories/getAttributes',
    async (categoryId, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${attributesURL}?categoryId=${categoryId}`,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            if (response.data.message === 'success') {
                return response.data;
            }
            return rejectWithValue('Failed to fetch attributes');
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Network error');
        }
    }
);

export const getPopularCategories = createAsyncThunk('categories/getPopularCategories',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.post(popularCategoriesURL, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.data.message === 'success') {
                return response.data.data;
            }
            return rejectWithValue('Failed to fetch popular categories');
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Network error');
        }
    }
);

const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        resetCategoryState: (state) => {
            state.categories = [];
            state.attributes = [];
            state.status = 'idle';
            state.error = null;
            state.products = [];
            state.category = null;
            state.categoryLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.status = 'success';
                state.categories = action.payload.data;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getPopularCategories.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getPopularCategories.fulfilled, (state, action) => {
                state.status = 'success';
                state.popularCategories = action.payload;
            })
            .addCase(getPopularCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getAttributes.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAttributes.fulfilled, (state, action) => {
                state.status = 'success';
                state.attributes = action.payload;
            })
            .addCase(getAttributes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getCategoryAndProducts.pending, (state) => {
                state.status = 'loading';
                state.error = null;
                state.categoryLoading = true;
                state.category = null;
                state.products = null;
                state.productsPagination = null;
            })
            .addCase(getCategoryAndProducts.fulfilled, (state, action) => {
                state.status = 'success';
                state.category = action.payload.data.category;
                state.products = action.payload.data.products;
                state.productsPagination = action.payload.data.pagination;
                state.categoryLoading = false;
            })
            .addCase(getCategoryAndProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.categoryLoading = false;
                state.error = action.payload || action.error.message;
                state.products = null;
                state.category = null;
                state.productsPagination = null;
            });
    },
});

export default categorySlice.reducer;