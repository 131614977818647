import { SvgIcon } from 'components/svg-icon/svg-icon';
import {
  Container,
  ImgWrapper,
  Main,
  Title,
  Price,
  Btn
} from './products-you-may-like-item-styled';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToCart, addProductToWishlist, removeProductFromWishlist } from '../../../../../redux/slices/product/productSlice';
import { Button } from 'components/categories-card/categories-card-styled';
import { formatMoney } from 'utils';
export const ProductsYouMauLikeItem = ({ product }) => {
  const dispatch = useDispatch();
  const { productsInCart, wishlist } = useSelector((state) => state.product);

  const handleAddToCart = () => {
    dispatch(addProductToCart({ product, quantity: 1, variation: {} }));
  };


 
  
  const isProductInCart = productsInCart?.some(item => item.product.id === product.id);
  const isProductInWishlist = wishlist?.some(item => item.product?.id === product?.id);
  
  return (
    <Container>
      <ImgWrapper>
        <img src={product?.image?.url} alt={product.title} />
      </ImgWrapper>
      <Main>
        <Title>{product?.title}</Title>
        <Price>{formatMoney(product.price?.normalPrice?.amount)}</Price>
      </Main>
      <Btn>
        
        {isProductInCart ? (
            <Button>
              Added
            </Button>
          ) : (
            <Button onClick={handleAddToCart}>
                <SvgIcon name='cart' />
             </Button>
          )}
      </Btn>
    </Container>
  );
};
