import Header from 'components/header/header';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import {
  Col,
  Container,
  Main,
  Subtitle,
  Title,
  TitleWrapper,
  TopWrapper,
  Wrapper
} from './cart-styled';
import { SponsoredAdv } from './components/sponosred-adv/sponsored-adv';
import { ProductsYouMayLike } from './components/products-you-may-like/products-you-may-like';
import { CartBlock } from './components/cart-block/cart-block';
import { useSelector, } from 'react-redux';
import img from './components/product-item/img/temp-img.png';
import { AdSpace } from './components/ad-space/ad-space';
import { GlobalButton } from '../../components/global-button/global-button';
import { clearCart } from '../../redux/slices/product/productSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCategoryAndProducts } from '../../redux/slices/category/categorySlice';
const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Cart' }
];

const products = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

const products2 = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

export const Cart = () => {
  const { productsInCart } = useSelector((state) => state.product);
  const [productsYouMayLike, setProductsYouMayLike] = useState([]);

  const dispatch = useDispatch();

  const handleClearCart = () => {
    dispatch(clearCart());
  }


  const fetchProductsYouMayLike = (category) => {
    dispatch(getCategoryAndProducts({ name: category, per_page: 8 }))
      .unwrap()
      .then((response) => {
        const filteredProducts = response.data.products.filter(
          (product) => !productsInCart.some((cartItem) => cartItem.product.id === product.id)
        );
        setProductsYouMayLike(filteredProducts.slice(0, 3));
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  };


  useEffect(() => {
    if (productsInCart.length > 0) {
      const categoryCounts = productsInCart.reduce((acc, item) => {
        const { product } = item;
        if (product.categories) {
          product.categories.forEach((category) => {
            acc[category] = (acc[category] || 0) + 1;
          });
        }
        return acc;
      }, {});
      const mostCommonCategory = Object.keys(categoryCounts).reduce((a, b) => 
        categoryCounts[a] > categoryCounts[b] ? a : b, null
      );

      if (mostCommonCategory) {
        fetchProductsYouMayLike(mostCommonCategory);
      }
    }
  }, [productsInCart, dispatch]);
  

  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Wrapper>
          <Col>
            {
              productsYouMayLike.length > 0 && (
                <ProductsYouMayLike products={productsYouMayLike} />
              )
            }
            <SponsoredAdv />
          </Col>
          <Main>
            <TopWrapper>
              <TitleWrapper>
                <Title>Cart Items</Title>
                <Subtitle>You have {productsInCart?.length} items in your cart</Subtitle>
                {productsInCart?.length > 0 && <p style={{ marginTop: 1, marginBottom: 10, color: 'red', cursor: 'pointer'}} onClick={handleClearCart}>Clear Cart</p>}
              </TitleWrapper>
              <GlobalButton type={'orange-secondary'}>Save for later</GlobalButton>
            </TopWrapper>

            <CartBlock />
          </Main>
          <Col>
            <SponsoredAdv />
            <AdSpace />
          </Col>
        </Wrapper>
      </Container>
    </>
  );
};
