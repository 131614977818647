import { Cart } from 'pages/cart/cart';
import CompanyPage from 'pages/company-page/company-page';
import { Dashboard } from 'pages/dashboard/dashboard';
import { AddNewProductSingle } from 'pages/dashboard/pages/add-new-product-single/add-new-product-single';
import { AddNewProduct } from 'pages/dashboard/pages/add-new-product/add-new-product';
import { DashboardMain } from 'pages/dashboard/pages/dashboard-main/dashboard-main';
import { ProductBulkUpload } from 'pages/dashboard/pages/product-bulk-upload/product-bulk-upload';
import { Subscriptions } from 'pages/dashboard/pages/subscriptions/subscriptions';
import { Home } from 'pages/home/home';
import { Login } from 'pages/login/login';
import { OrderTracking } from 'pages/order-tracking/order-tracking';
import { PopularCategories } from 'pages/popular-categories/popular-categories';
import { Register } from 'pages/register/register';
import { SingleProductGeneral } from 'pages/single-product-general/single-product-general';
import { SingleProductRealEstate } from 'pages/single-product-real-estate/single-product-real-estate';
import { SingleProductTradesAndServices } from 'pages/single-product-trades-services/single-product-trades-and-services';
import { StoreFinder } from 'pages/store-finder/store-finder';
import { StoreFrontRealEstate } from 'pages/store-front-real-estate/store-front-real-estate';
import { StoreFrontTradesServices } from 'pages/store-front-trades-services/store-front-trades-services';
import { StoresYouLove } from 'pages/stores-you-love/stores-you-love';
import { Routes } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { Blog } from '../../pages/blog/blog';
import { BlogArticle } from '../../pages/blog/pages/blog-article/blog-article';
import { BlogUpdates } from '../../pages/blog/pages/updates/blog-updates';
import { BlogVideos } from '../../pages/blog/pages/videos/blog-videos';
import BuyerRequests from '../../pages/buyer-requests/buyer-requests';
import CategoriesClothingApparel from '../../pages/categories-clothing-apparel/categories-clothing-apparel';
import CategoriesFoodAndGrocery from '../../pages/categories-food-and-grocery/categories-food-and-grocery';
import CategoriesHats from '../../pages/categories-hats/categories-hats';
import CategoriesMotorVehiclesSpecificSearch from '../../pages/categories-motor-vehicles-specific-search/categories-motor-vehicles-specific-search';
import CategoriesMotorVehiclesGenericSearch from '../../pages/categories-motor-vehicles-generic-search/categories-motor-vehicles-generic-search';
import CategoriesMotorVehicles from '../../pages/categories-motor-vehicles/categories-motor-vehicles';
import CategoriesRealEstate from '../../pages/categories-real-estate/categories-real-estate';
import Categories from '../../pages/categories/categories';
import { Checkout } from '../../pages/checkout/checkout';
import { AddNewProductSingleAuction } from '../../pages/dashboard/pages/add-new-product-single-auction/add-new-product-single-auction';
import { AddNewProductSingleForHireItem } from '../../pages/dashboard/pages/add-new-product-single-for-hire-item/add-new-product-single-for-hire-item';
import { AddNewProductSingleService } from '../../pages/dashboard/pages/add-new-product-single-service/add-new-product-single-service';
import AllCustomers from '../../pages/dashboard/pages/all-customers/all-customers';
import AllFollowers from '../../pages/dashboard/pages/all-followers/all-followers';
import AllOrders from '../../pages/dashboard/pages/all-orders/all-orders';
import AllSellItemsDashboard from '../../pages/dashboard/pages/all-sell-items/all-sell-items';
import AllServices from '../../pages/dashboard/pages/all-services/all-services';
import { BusinessInformation } from '../../pages/dashboard/pages/business-information/business-information';
import { Calendar } from '../../pages/dashboard/pages/calendar/calendar';
import { CreateNewOrder } from '../../pages/dashboard/pages/create-new-order/create-new-order';
import CustomerList from '../../pages/dashboard/pages/customer-list/customer-list';
import { Inbox } from '../../pages/dashboard/pages/inbox/inbox';
import { Messages } from '../../pages/dashboard/pages/inbox/pages/messages/messages';
import { NotificationsDashboard } from '../../pages/dashboard/pages/inbox/pages/notifications/notifications';
import ManageAllStaffMembers from '../../pages/dashboard/pages/manage-all-staff-members/manage-all-staff-members';
import NewOrders from '../../pages/dashboard/pages/new-orders/new-orders';
import Newsletter from '../../pages/dashboard/pages/newsletter/newsletter';
import { AccountSettings } from '../../pages/profile/pages/account-settings/account-settings';
import { AddNewProductFormAuctionProfile } from '../../pages/profile/pages/add-new-product-form-auction/add-new-product-form-auction';
import { AddNewProductFormHireItemProfile } from '../../pages/profile/pages/add-new-product-form-hire-item/add-new-product-form-hire-item';
import { AddNewProductFormProfile } from '../../pages/profile/pages/add-new-product-form/add-new-product-form';
import { AddNewProductAccount } from '../../pages/profile/pages/add-new-product/add-new-product';
import { AllSellItems } from '../../pages/profile/pages/all-sell-items/all-sell-items';
import { AppointmentsRespond } from '../../pages/profile/pages/appointments-respond/appointments-respond';
import { Appointments } from '../../pages/profile/pages/appointments/appointments';
import { CalendarProfile } from '../../pages/profile/pages/calendar/calendar';
import { FavoriteProducts } from '../../pages/profile/pages/favorite-products/favorite-products';
import { MyOrders } from '../../pages/profile/pages/my-orders/my-orders';
import { NewOrdersProfile } from '../../pages/profile/pages/new-orders/new-orders';
import { Notifications } from '../../pages/profile/pages/notifications/notifications';
import { NewOrderProfile } from '../../pages/profile/pages/order/order';
import { ProductBulkUploadProfile } from '../../pages/profile/pages/product-bulk-upload/product-bulk-upload';
import { ResetPassword } from '../../pages/reset-password/reset-password';
import { ShoppingCenterSingle } from '../../pages/shopping-center-single/shopping-center-single';
import { ShoppingCentresFiltered } from '../../pages/shopping-centres-filtered/shopping-centres-filtered';
import { ShoppingCentres } from '../../pages/shopping-centres/shopping-centres';
import { SingleProductAuctionRealEstate } from '../../pages/single-product-auction-real-estate/single-product-auction-real-estate';
import { SingleProductAuction } from '../../pages/single-product-auction/single-product-auction';
import { ThankYou } from '../../pages/thank-you/thank-you';
import App from '../app/app';
import { AllAppointments } from '../../pages/dashboard/pages/all-appointments/all-appointments';
import { CompareAccount } from '../../pages/compare-accounts/compare-account';
import CategoriesClothingApparelNearYou from '../../pages/categories-clothing-apparel/categories-clothing-apparel-near-you';
import { BuyerRequestsList } from '../buyer-requests-list/buyer-requests-list';
import { InboxProfile } from '../../pages/profile/pages/inbox-profile/inbox-profile';
import { MessagesProfile } from '../../pages/profile/components/messages/messages-profile';
import { OffersProfile } from '../../pages/profile/pages/offers-profile/offers-profile';
import { DealsOfTheDay } from '../../pages/deals-of-the-day/deals-of-the-day';
import { StorefrontWrapper } from '../storefront-wrapper/storefront-wrapper';
import { StoreFrontGeneralStore } from '../../pages/store-front-general-store/store-front-general-store';
import { StoreFrontFood } from '../../pages/store-front-food/store-front-food';
import { StoreFrontVehicle } from '../../pages/store-front-vehicle/store-front-vehicle';
import CategoriesGeneral from '../../pages/categories-general/categories-general';
import CategoriesGeneralNearYou from '../../pages/categories-general/categories-general-near-you';
import { AccountsWrapper } from '../../pages/profile/components/accounts-wrapper/accounts-wrapper';
import { AddNewProductSingleServiceProfile } from '../../pages/profile/pages/add-new-product-single-service/add-new-product-single-service';
import { AddNewProductSingleAuctionProfile } from '../../pages/profile/pages/add-new-product-single-auction/add-new-product-single-auction';
import { AddNewProductSingleForHireItemProfile } from '../../pages/profile/pages/add-new-product-single-for-hire-item/add-new-product-single-for-hire-item';
import { Provider } from 'react-redux';
import { persistor, store } from '../../redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from 'context/auth-context';
import { getRouteById } from 'routes/routesHelper';


export const Main = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}></PersistGate>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />}>

            {/* Start Active Routes */}
            <Route path= {getRouteById('storefront')} element={<StorefrontWrapper />}>
              <Route path={ getRouteById('storefront') } element={<StoreFrontGeneralStore />} />
            </Route>
            {/* <Route path={ getRouteById('dashboard') } element={<Dashboard />} /> */}
            {/* <Route path={ getRouteById('allOrders') } element={<AllOrders />} />
            <Route path={ getRouteById('newOrders') } element={<NewOrders />} />
            <Route path={ getRouteById('myOrders') } element={<MyOrders />} /> */}

            {/* End Active Routes */}
             
             
            <Route path='' element={<Home />} />
            <Route path='company' element={<CompanyPage />} />
            <Route path='login' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route path='password-reset' element={<ResetPassword />} />
            <Route path='compare-accounts' element={<CompareAccount />} />
            <Route path={getRouteById('storeFinder')} element={<StoreFinder />} />
            <Route path='deals-of-the-day' element={<DealsOfTheDay />} />
            {/* Categories */}
            <Route path='categories-general'>
              <Route path={''} element={<CategoriesGeneral />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route path={getRouteById('category')} element={<CategoriesGeneral />} />
            <Route path='popular-categories' element={<PopularCategories />} />
            <Route path='categories-trades-and-services'>
              <Route path={''} element={<Categories />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route path='categories-real-estate'>
              <Route path={''} element={<CategoriesRealEstate />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route path='categories-motor-vehicles'>
              <Route path={''} element={<CategoriesMotorVehicles />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route path='categories-motor-vehicles-spare-parts'>
              <Route path={''} element={<CategoriesMotorVehicles isSpareParts />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route
              path='categories-motor-vehicles-specific-search'
              element={<CategoriesMotorVehiclesSpecificSearch />}
            >
              <Route path={''} element={<CategoriesMotorVehiclesSpecificSearch />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route path='categories-motor-vehicles-generic-search'>
              <Route path={''} element={<CategoriesMotorVehiclesGenericSearch />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route path='categories-food-and-grocery'>
              <Route path={''} element={<CategoriesFoodAndGrocery />} />
              <Route path={'near-you'} element={<CategoriesGeneralNearYou />} />
            </Route>
            <Route path='categories-clothing-apparel'>
              <Route path={''} element={<CategoriesClothingApparel />} />
              <Route path={'near-you'} element={<CategoriesClothingApparelNearYou />} />
            </Route>

            <Route path='categories-hats'>
              <Route path={''} element={<CategoriesHats />} />
              <Route path={'near-you'} element={<CategoriesClothingApparelNearYou />} />
            </Route>

            {/* Storefront */}
            <Route path='storefront' element={<StorefrontWrapper />}>
              <Route path='real-estate' element={<StoreFrontRealEstate />} />
              <Route path='trades-services' element={<StoreFrontTradesServices />} />
              <Route path='food' element={<StoreFrontFood />} />
              <Route path='motor-vehicles' element={<StoreFrontVehicle />} />
              <Route path='general' element={<StoreFrontGeneralStore />} />
            </Route>

            {/* Storefront END*/}
            <Route path={getRouteById('product')} element={<SingleProductGeneral />} />
            <Route path={getRouteById('stores')} element={<StoresYouLove />} />
            <Route path='single-product-real-estate' element={<SingleProductRealEstate />} />
            <Route
              path='single-product-trades-services'
              element={<SingleProductTradesAndServices />}
            />
            <Route path='single-product-auction' element={<SingleProductAuction />} />
            <Route
              path='single-product-auction-approved'
              element={<SingleProductAuction status={'approved'} />}
            />
            <Route
              path='single-product-auction-rejected'
              element={<SingleProductAuction status={'rejected'} />}
            />

            <Route
              path='single-product-auction-real-estate'
              element={<SingleProductAuctionRealEstate />}
            />
            <Route
              path='single-product-auction-real-estate-approved'
              element={<SingleProductAuctionRealEstate status={'approved'} />}
            />
            <Route
              path='single-product-auction-real-estate-rejected'
              element={<SingleProductAuctionRealEstate status={'rejected'} />}
            />

            <Route path='thank-you' element={<ThankYou />} />

            <Route path='blog'>
              <Route path='' index element={<Blog />} />
              <Route path='videos' element={<BlogVideos />} />
              <Route path='updates' element={<BlogUpdates />} />
              <Route path='article/:id' element={<BlogArticle />} />
            </Route>
            <Route path='checkout' element={<Checkout />} />
            <Route path='cart' element={<Cart />} />


            <Route path='dashboard' element={<Dashboard />}>
              <Route path='' element={<DashboardMain />} />

              <Route path='settings'>
                <Route path='business-information' element={<BusinessInformation />} index />
              </Route>
              <Route path='add' element={<AddNewProduct />} />
              <Route path='bulk-upload' element={<ProductBulkUpload />} />
              <Route path='subscriptions' element={<Subscriptions />} />
              <Route path={getRouteById('addProductSingle')} element={<AddNewProductSingle />} />
              <Route path='add-new-product-auction' element={<AddNewProductSingleAuction />} />
              <Route
                path='add-new-product-hire-item'
                element={<AddNewProductSingleForHireItem />}
              />

              <Route path='order/:id' element={<NewOrderProfile />} />
              <Route path='orders'>
                <Route path='' element={<AllOrders />} index/>
                <Route path='new-orders' element={<NewOrders />} />
                <Route path='create-order' element={<CreateNewOrder />} />
                <Route path='my-orders' element={<MyOrders />} />
                <Route path='new-orders' element={<NewOrdersProfile />} />
              </Route>
              
              <Route path='add-new-product-service' element={<AddNewProductSingleService />} />
              <Route path='business-information' element={<BusinessInformation />} />
              <Route path='all-sell-items' element={<AllSellItemsDashboard />} />
              <Route path='customer-list' element={<CustomerList />} />
              <Route path='newsletter' element={<Newsletter />} />
              <Route path='all-services' element={<AllServices />} />
              <Route path='inbox' element={<Inbox />}>
                <Route path='messages' element={<Messages />} />
                <Route path='notifications' element={<NotificationsDashboard />} />
              </Route>
              <Route path='all-customers' element={<AllCustomers />} />
              <Route path='all-followers' element={<AllFollowers />} />
              <Route path='calendar' element={<Calendar />} />
              <Route path='manage-all-staff-members' element={<ManageAllStaffMembers />} />
              <Route path='all-appointments' element={<AllAppointments />} />
            </Route>
            <Route path='order-tracking' element={<OrderTracking />} />
            <Route path='account' element={<AccountsWrapper />}>
              <Route path='' index element={<AccountSettings />} />
              <Route path='inbox' element={<InboxProfile />}>
                <Route path='messages' element={<MessagesProfile />} />
                <Route path='notifications' element={<Notifications />} />
                <Route path='offers' element={<OffersProfile />} />
              </Route>
              <Route path={getRouteById('favourite')} element={<FavoriteProducts />} />
              <Route path='add' element={<AddNewProductAccount />} />
              <Route path='upload' element={<ProductBulkUploadProfile />} />
              <Route path='publish-product' element={<AddNewProductFormProfile />} />
              <Route path='publish-product-auction' element={<AddNewProductFormAuctionProfile />} />
              <Route
                path='publish-product-hire-item'
                element={<AddNewProductFormHireItemProfile />}
              />
              <Route path='appointments' element={<Appointments />} />
              <Route path='appointments-respond' element={<AppointmentsRespond />} />
              <Route path='all-sell-items' element={<AllSellItems />} />
              <Route path='calendar' element={<CalendarProfile />} />
              <Route
                path='add-new-product-service'
                element={<AddNewProductSingleServiceProfile />}
              />
              <Route
                path='add-new-product-auction'
                element={<AddNewProductSingleAuctionProfile />}
              />
              <Route
                path='add-new-product-hire-item'
                element={<AddNewProductSingleForHireItemProfile />}
              />
              <Route
                path='add-new-product'
                element={<AddNewProductSingle isProductPromotion={true} />}
              />
            </Route>
            <Route path='buyer-requests' element={<BuyerRequests />} />
            <Route path='buyer-requests-list' element={<BuyerRequestsList />} />
            <Route path='shopping-centres' element={<ShoppingCentres />} />
            <Route path='shopping-centres/filtered' element={<ShoppingCentresFiltered />} />
            <Route path='shopping-centres/:id' element={<ShoppingCenterSingle />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
