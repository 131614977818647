import React, { useCallback } from 'react';
import './input.scss';

import arrow from '../../assets/arrow_up.svg';
import search from '../../assets/search.svg';
import { useState } from 'react';
import { debounce } from 'lodash';
import { searchProducts } from './../../redux/slices/Search/searchSlice';
import { useDispatch } from 'react-redux';
const Input = ({
    value,
    onChange,
    wrapperClassName,
    inputClassName,
    width,
    height,
    placeholder,
    borderColor,
    dropdownOnClick,
    withoutCategories = false,
    suggestions = [],
    selectedCategory,
    onSearch,
    isDropdownOpen,
    setIsDropdownOpen
}) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const debouncedFetchSuggestions = useCallback(
        debounce(async (inputValue) => {
            if (inputValue.trim()) {
                try {
                    setIsLoading(true);

                    const response = await fetch(
                        `http://everythingzimbabwean.test/wp-json/ezw/v1/search?term=${inputValue}&per_page=5`
                    );

                    const data = await response.json();
                    setFilteredSuggestions(data.data || []);
                    setIsDropdownOpen(data.data.length > 0);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                    setFilteredSuggestions([]);
                    setIsDropdownOpen(false);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setFilteredSuggestions([]);
                setIsDropdownOpen(false);
            }
        }, 100),
        []
    );

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        onChange(e);
        debouncedFetchSuggestions(inputValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearch(value);
        }
    };
    const handleSuggestionClick = (suggestion) => {
        onChange({ target: { value: suggestion } });
        setIsDropdownOpen(false);
        onSearch(suggestion);
    };

    const dropdownStyles = {
        position: 'absolute',
        backgroundColor: 'white',
        // border: '1px solid #ddd',
        border: 'none',
        borderRadius: '2px',
        overflowY: 'auto',
        width: '100%',
        zIndex: 0,
        marginTop: '4px',
        padding: '0',
        listStyleType: 'none'
    };

    const suggestionItemStyles = {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: 'none',
        fontSize: '14px',
        fontWeight: '400',
        alignItems: 'center',
        gap: '10px',
        display: 'flex'
    };

    const suggestionItemHoverStyles = {
        backgroundColor: '#F5F7FB'
    };

    return (
        <section className={`${wrapperClassName} input-global`} style={{ width: width }}>
            <input
                id={'input'}
                className={`${inputClassName} `}
                type='text'
                value={value}
                onChange={handleInputChange}
                style={{
                    height: height,
                    border: `1px solid ${!borderColor ? '#E2E5EB' : borderColor}`
                }}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
            ></input>
            <div className='controls'>
                {!withoutCategories ? (
                    <div
                        className='dropdown'
                        onClick={dropdownOnClick ? dropdownOnClick : () => {}}
                    >
                        <p className='name'>{selectedCategory ? selectedCategory : 'Category'}</p>
                        <img src={arrow} alt='' />
                    </div>
                ) : (
                    ''
                )}
                <div className='search-button' onClick={() => onSearch(value)}>
                    <img src={search} alt='' />
                </div>
            </div>
            {isDropdownOpen && (
                <ul style={dropdownStyles}>
                    {filteredSuggestions.slice(0, 8).map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion.title)}
                            style={suggestionItemStyles}
                            onMouseEnter={(e) =>
                                (e.target.style.backgroundColor =
                                    suggestionItemHoverStyles.backgroundColor)
                            }
                            onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
                        >
                            <img
                                src={suggestion.image.url}
                                alt=''
                                style={{
                                    width: '30px', // Control image width
                                    height: '30px', // Control image height
                                    objectFit: 'cover', // Maintain aspect ratio without distortion
                                    borderRadius: '5px' // Optional: Rounded corners
                                }}
                            />
                            <span>{suggestion.title}</span>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
};

export default Input;
