import { useState } from 'react';
import { RateStars } from '../rate-stars/rate-stars';
import {
    Buttons,
    Container,
    MainButtons,
    MataInfoItem,
    MetaInfo,
    MetaInfoText,
    MetaInfoTitle,
    Orders,
    Price,
    RatingWrapper,
    Reviews,
    ReviewsOrders,
    ShopName,
    Stock,
    Title,
    Description,
    MeniItem,
    ShareBtn,
    MobileContactSellerBtn
} from './product-info-styled'; // Ensure this path is correct
import { MakeOfferPopup } from '../make-offer-popup/make-offer-popup';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToCart, removeProductFromCart, updateProductInCart } from '../../redux/slices/product/productSlice';
import { Link } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';
import { Colors } from './components/colors/colors';
import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import { SelectWithButtons } from './components/select-with-buttons/select-with-buttons';
import { Counter } from './components/counter/counter';
import { DeliveryInfo } from './components/delivery-info/delivery-info';
import { SocialLinks } from 'components/store-front-top/store-front-top-styled';
import { formatMoney } from 'utils';


export const ProductInfo = ({ onContactSellerBtnClick, product,business }) => {
    const dispatch = useDispatch();
    const { productsInCart } = useSelector((state) => state.product);
    const [isMakeOfferVisible, setMakeOfferVisible] = useState(false);
    const capacityMock = [32, 64, 128]; // Example values for storage capacities
    const [currentCapacity, setCurrentCapacity] = useState(capacityMock[0]);
    const [quantity, setQuantity] = useState(1);
    const condition = product?.data?.condition;
    const dateAdded = product?.data?.dateAdded;
    const price = formatMoney(product?.data?.price.sellingPrice?.amount || product?.data?.price.normalPrice?.amount);
    const title = product?.data?.title;
    const description = product?.data?.description;
    const totalOrders = product?.data?.totalOrders;
    const id = product?.data?.id;
    const rating = product?.data?.rating || 0;
    const businessName = product?.data?.business?.name;
    const whatsAppNumber = product?.data?.business.data?.whatsApp;
    const phoneNumber = product?.data?.business.data?.phone;
    const email = product?.data?.business.data?.email;
  const totalReviews = product?.data?.product?.data?.totalReviews || 0;
  
    
    //format the date
    const dateObject = new Date(dateAdded);
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
    const year = dateObject.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    const formatPhoneNumber = (phone, countryCode = '+23') => {
  
        if (phone.startsWith('+')) {
          return phone;
        }
        return countryCode + phone.replace(/^0/, ''); // Remove leading '0' if present
    };
  
    const isProductInCart = productsInCart?.some(item => item.product.id === product.data.id);

    const handleAddToCart = () => {
        dispatch(addProductToCart({ product: product.data, quantity: quantity, variation: { } }))
    }
    
      const formattedPhoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : '';
      const formattedWhatsAppNumber = whatsAppNumber ? formatPhoneNumber(whatsAppNumber) : '';

        return (
        <Container>
          <ShopName>
            <Link to={ getRouteById('storefront', {bSlug: product?.data?.business.slug} ) }>
                {businessName}
            </Link>
            </ShopName>
          <Title>{title}</Title>
          <RatingWrapper>
            <RateStars rate={rating}  height={17} width={17} isRateCounter={false} />
            <ReviewsOrders>
              <Reviews>
                <a href='#'>{totalReviews} Reviews</a>
              </Reviews>
              <Orders>{totalOrders} Orders</Orders>
            </ReviewsOrders>
          </RatingWrapper>
          <MetaInfo>
            <MataInfoItem>
              <MetaInfoTitle>Date Added:  </MetaInfoTitle>
              <MetaInfoText>{formattedDate}</MetaInfoText>
            </MataInfoItem>
            <MataInfoItem>
              <MetaInfoTitle>ID: </MetaInfoTitle>
              <MetaInfoText>{id}</MetaInfoText>
            </MataInfoItem>
          </MetaInfo>
          <Price>{price}</Price>
          <Stock>17 in Stock</Stock>
          <Description>
            {description}
          </Description>
          {/* <MeniItem>
            <Colors />
          </MeniItem>*/}
           <MeniItem>
            {/*  <SelectWithButtons
              title={'Storage capacity:'}
              onClick={setCurrentCapacity}
              values={capacityMock}
              currentValue={currentCapacity}
              /> */}
          </MeniItem>
          <MeniItem className={'flex'}>
            <Counter title={'Quantity:'} currentValue={quantity} setValue={setQuantity} />
            {/* <ShareBtn>
              <SvgIcon name={'share'} />
              Share
            </ShareBtn> */}
          </MeniItem>
          <Buttons>
            <MainButtons>
            {isProductInCart ? 
                  <Link to='/cart'>
                      <GlobalButton height={40} type='orange'>
                        Checkout
                      </GlobalButton>
                  </Link>:
                  <GlobalButton height={40} type='primary' onClick={handleAddToCart}>
                      <SvgIcon name={'cart'} />
                      Add to cart
                  </GlobalButton>
              }
              <GlobalButton height={40} type={'orange-secondary'}>
                <SvgIcon name={'wallet'} />
                Buy Now
              </GlobalButton>
              {/* <GlobalButton
                height={40}
                type={'orange'}
                onClick={() => {
                  setMakeOfferVisible(true);
                }}
              >
                <SvgIcon name={'message-text-square'} />
                Make an Offer
              </GlobalButton> */}
            </MainButtons>
            
              <SocialLinks>
                 <a href={`https://wa.me/${formattedWhatsAppNumber}`} target="_blank" rel="noopener noreferrer">
                   <SvgIcon name={'whatsup'} />
                 </a>
                 <a href={`tel:${formattedPhoneNumber}`}>
                   <SvgIcon name={'phone'} />
                 </a>
                 <a href={`mailto:${email}`}>
                   <SvgIcon name={'email'} />
                 </a>
               </SocialLinks>
            
          </Buttons>
          <MobileContactSellerBtn>
            <GlobalButton type={'primary'} onClick={onContactSellerBtnClick}>
              Contact Seller
            </GlobalButton>
          </MobileContactSellerBtn>
          {/* <DeliveryInfo /> */}
          {/* <MakeOfferPopup
            isVisible={isMakeOfferVisible}
            onClose={() => {
              setMakeOfferVisible(false);
            }}
            onMakeOfferBtnClick={() => {
              setMakeOfferVisible(false);
            }}
          /> */}
        </Container>
      );
};