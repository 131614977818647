import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const knowledgeBaseURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_NEWS_UPDATES_API_URL;

const initialState = {
  articles: [], 
  status: 'idle', 
  error: null,

};

export const getKnowledgeBaseArticles = createAsyncThunk(
    'knowledgeBase/getKnowledgeBaseArticles',
    async (params, { rejectWithValue }) => {
        const { slug } = params;
        
        // Construct the URL with the slug as a query parameter
        const fullUrl = `${knowledgeBaseURL.replace(/\/$/, '')}?slug=${slug}`;
        
        console.log('Requesting URL:', fullUrl); // Check the final URL
        
        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log("response", response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            const errorMessage = err.response?.data?.message || 'An error occurred';
            return rejectWithValue(errorMessage);
        }
    }
);


const knowledgeBaseSlice = createSlice({
  name: 'knowledgeBase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKnowledgeBaseArticles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKnowledgeBaseArticles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = action.payload.articles; // Assuming the response has 'articles'
      })
      .addCase(getKnowledgeBaseArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch data';
      });
  },
});

export default knowledgeBaseSlice.reducer;