
import React from 'react';
import { BlogCardContainer, ImgWrapper, Main, Title, Description, Date } from './blog-card-styled'; // Your styled components

export const BlogCard = ({ blog, isLoading }) => {

  const handleCardClick = () => {
    window.location.href = blog.link;
  };
  
  return (
    <BlogCardContainer onClick={handleCardClick}>
        <ImgWrapper>
          {/* Check if the blog has a featured image */}
          {blog.featuredImage ? (
            <img src={blog.featuredImage}  style={isLoading ? { opacity: 0, transition: 'opacity 0.3s ease' } : { opacity: 1 }} />
          ) : (
            // Render a placeholder element if no featured image exists
            <div
              className="placeholder"
              style={{
                width: '100%',
                height: '200px',
                backgroundColor: '#e0e0e0',
                borderRadius: '8px',
              }}
            >
              {/* Placeholder content or style */}
            </div>
          )}
        </ImgWrapper> 
        <Main>
          {/* Render the title and description */}
          <Title dangerouslySetInnerHTML={{ __html: blog.title }} />
          <Description dangerouslySetInnerHTML={{ __html: blog.content }} />
          <Date>{blog.createdDate}</Date>
        </Main>
    </BlogCardContainer>
  );
};
