import React, { useEffect } from 'react';

import {
    Badge,
    Buttons,
    BuyBtn,
    Container,
    Content,
    FavBtn4,
    ImgWrapper,
    SubTitle,
    Main,
    MainPrice,
    RateStarsDesktop,
    OldPrice,
    MainBox,
    Price,
    LabelNotAvailable,
    ContactsBox,
    Title,
    Label,
    ZWLPrice,
    LabelInStock,
    LabelOutStock,
    Text
} from './p-card-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import { RateStars } from '../rate-stars/rate-stars';
import { ApproveSeller } from '../approve-seller/approve-seller';
import { Button, Description } from 'components/categories-card/categories-card-styled';
import {
    DescriptionItem,
    DescriptionListItem
} from 'pages/dashboard/components/side-preview/side-preview-styled';
import { getRouteById } from 'routes/routesHelper';
import { Link } from 'react-router-dom';
import { formatMoney, formatWhatsAppPhoneNumber } from 'utils';
import { addProductToCart, addProductToWishlist, removeProductFromWishlist } from '../../redux/slices/product/productSlice';
import { useDispatch , useSelector} from 'react-redux';

export const PCardStoreFront = ({ product, handleFavBtnClick, isFav, business }) => {
    const dispatch = useDispatch();
    const productUrl = getRouteById('product', { slug: product.slug }); 
    const { productsInCart, wishlist } = useSelector((state) => state.product);

    const handleAddToCart = () => {
        // dispatch(addProductToCart({ product, quantity: 1, variation: { color: 'red', size: 'M' } }));
        dispatch(addProductToCart({ product, quantity: 1, variation: { } }))
    };

    const handleAddToWishlist = () => {
        dispatch(addProductToWishlist({ product }));
    };

    const handleRemoveFromWishlist = () => {
        dispatch(removeProductFromWishlist({ productId: product.id }));
    };
    
    const isProductInCart = productsInCart?.some(item => item.product.id === product.id);
    const isProductInWishlist = wishlist?.some(item => item.id === product.id);

    return (

          <Container>
          
            <ImgWrapper>
                <ApproveSeller />
                    <img src={product?.image?.url || ''} alt={product?.image?.altText || ''} />
                {product?.availability.status === true ? (
                    <LabelInStock>In Stock</LabelInStock>
                ) : (
                    <LabelOutStock>Out Of Stock</LabelOutStock>
                )}
                {/* {product.delivery ? (
                    <Label>Delivery Available</Label>
                    ) : (
                    <LabelNotAvailable>Delivery not available</LabelNotAvailable>
                    )} */}
                  <FavBtn4 onClick={isProductInWishlist ? handleRemoveFromWishlist : handleAddToWishlist} isFav={isProductInWishlist}>
                        <SvgIcon name={'heart-2'} />
                  </FavBtn4>
            </ImgWrapper>
           -
            <Main>
                <MainBox>
                    <RateStarsDesktop>
                        {product.rating && (
                            <RateStars
                                rate={product.rating}
                                reviews={product.totalReviews}
                                color={'#388638'}
                            />
                        )}
                    </RateStarsDesktop>
                    <ContactsBox>
                       {product.business?.whatsApp &&
                          <a
                          href={`https://wa.me/${formatWhatsAppPhoneNumber(product.business?.whatsApp)}?text=Hi,%20I%20saw%20your%20shop%20on%20https://everythingzimbabwean.com%20and%20I'd%20like%20to%20ask%20about%20${product.title}`}
                          type='tel'
                          target='_blank'
                      >
                          <SvgIcon name='whatsup'/>
                      </a>
                       }

                        <a href={`mailto:${product.business?.email}`} target='_blank'>
                            <SvgIcon name={'email'} />
                        </a>
                        <a href={`tel:${product.business?.phone}`} target='_blank'>
                            <SvgIcon name={'phone'} />
                        </a>
                    </ContactsBox>
                </MainBox>
                <Link to={productUrl}>
                    <Title>{product.title}</Title>
                    <Text>
                        {product.description ? product.description.substring(0, 80) + '...' : ''}
                    </Text>
                </Link>
                <SubTitle>
                   <Link to="#">
                     {product.business.name}
                   </Link> {product.business.address.city ? '|' + product.business.address.city: ''}
                </SubTitle>

                <Content>
                    <Price>
                    
                        {
                            product.price.sellingPrice ?
                            <>
                                <OldPrice>
                                    {/* {product.price.normalPrice?.currency + ' '} */}
                                    {formatMoney(product.price.normalPrice?.amount)}
                                </OldPrice>
                                <MainPrice>
                                    {product.price.sellingPrice?.currency + ' '}
                                    {product.price.sellingPrice?.amount}
                                </MainPrice>
                            </>
                            :
                            <>
                                <MainPrice>
                                    {/* {product.price.normalPrice?.currency + ''} */}
                                    {formatMoney(product.price.normalPrice?.amount)}
                                </MainPrice>
                            </>
                        }
                          
                    </Price>

                    {/* <Price> */}
                    {/* {product.price.some((price) => price.currency === 'USD') ? (
                            <>
                                <OldPrice>
                                    ${' '}
                                    {product.price.find((price) => price.currency === 'USD')
                                        ?.regular_price || ''}
                                </OldPrice>
                                <MainPrice>
                                    USD{' '}
                                    {product.price.find((price) => price.currency === 'USD')
                                        ?.sale_price || ''}
                                </MainPrice>
                            </>
                        ) : (
                            ''
                        )}
                        {product.price.some((price) => price.currency === 'USD') ? (
                            <>
                                <ZWLPrice>
                                    ZIG
                                    {product.price.find((price) => price.currency === 'ZIG')
                                        ?.regular_price || ''}
                                </ZWLPrice>
                            </>
                        ) : (
                            ''
                        )} */}
                    {/* 
                        {product.price.normalPrice.find((price) => price.currency === 'ZAR').amount
                            .length ? (
                            <ZWLPrice>
                                ZIG
                                {product.price.normalPrice.find((price) => price.currency === 'ZIG')
                                    ?.amount || ''}
                            </ZWLPrice>
                        ) : (
                            ''
                        )}
                        <ZWLPrice>
                            ZIG{' '}
                            {product.price.normalPrice.find((price) => price.currency === 'ZIG')
                                ?.amount || ''}
                        </ZWLPrice> */}
                    {/* </Price> */}
                    <Buttons>
                        {isProductInCart ? (
                            <Link to="/cart">
                                 <Button>
                                    View
                                </Button>
                            </Link>
                        ) : (
                            <BuyBtn onClick={handleAddToCart}>
                                <SvgIcon name='cart' />
                            </BuyBtn>
                        )}
                    </Buttons>
                </Content>
                {product.badge && <Badge>{product.badge}</Badge>}
            </Main>
        </Container>
    );
};
