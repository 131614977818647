import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const searchURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_SEARCH_API_URL;

const initialState = {
    searchedProducts: [],
    status: 'idle',
    error: null,
    message: '',
    term: ''
};

export const searchProducts = createAsyncThunk(
    'search/searchProducts',
    async ({ term, category = '', per_page }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${searchURL}`, {
                params: { term, category, per_page },
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.status === 200) {
                // console.log('Slice response', response);

                return response.data;
            } else {
                // console.log('Slice response', response);
                return rejectWithValue('Failed to fetch products');
            }
        } catch (error) {
            return rejectWithValue(error.message || 'Network error');
        }
    }
);
const searchSlice = createSlice({
    name: 'searchProducts',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(searchProducts.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(searchProducts.fulfilled, (state, action) => {
                state.status = 'success';
                state.searchedProducts = action.payload.data;
            })
            .addCase(searchProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default searchSlice.reducer;
