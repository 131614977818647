import { CartPopupStyled, CloseBtn, Main, Title, Top } from '../cart-popup/cart-popup-styled';
import { ReactComponent as CloseIcon } from '../cart-popup/img/close.svg';
import { CartPopupBlock } from '../cart-popup/components/cart-popup-block/cart-popup-block';
import img from '../../../../pages/cart/components/product-item/img/temp-img.png';
import { ProductsYouMayLike } from '../cart-popup/components/products-you-may-like/products-you-may-like';
import { FavouritePopupBlock } from './components/favourite-popup-block/favourite-popup-block';

const products = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

const products2 = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

export const FavouritePopup = ({ isOpened, onCloseBtnClick }) => {
  return (
    <CartPopupStyled isOpened={isOpened}>
      <Top>
        <Title>Favourite Products</Title>
        <CloseBtn onClick={onCloseBtnClick}>
          <CloseIcon />
        </CloseBtn>
      </Top>
      <Main>
        <FavouritePopupBlock />
        <ProductsYouMayLike />
      </Main>
    </CartPopupStyled>
  );
};