import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import {
  Box,
  ButtonAllShow,
  ButtonMobile,
  Categories,
  Category,
  Container,
  ControlsSecond,
  DashboardBtnWrapper,
  DeleteButton,
  Img,
  ImgMobile,
  Main,
  Navigation,
  Photo,
  Product,
  ProductBox,
  ProductButtons,
  ProductDescription,
  ProductInfo,
  ProductPhoto,
  ProductPrice,
  ProductRating,
  ProductRatingTitle,
  Products,
  ProductsBox,
  ProductStatus,
  ProductTitle,
  RatingCount,
  SidebarWrapper,
  SortBy,
  Span,
  Title,
  ViewButton
} from './favorite-products-styled.jsx';
import { addProductToCart, addProductToWishlist, removeProductFromWishlist } from '../../../../redux/slices/product/productSlice';
import { ReactComponent as DeleteIco } from '../../../../assets/icons/delete-ico.svg';
import star from '../../../../assets/icons/star.svg';
import starMobile from '../../../../assets/icons/star-ico.svg';
import { ReactComponent as View } from '../../../../assets/icons/view.svg';
import { formatMoney } from 'utils';

const breadcrumbs = [
  { link: '#', name: 'Favorites' },
  { link: '#', name: 'Favorite Products' }
];

export const FavoriteProducts = () => {
  const dispatch = useDispatch();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { productsInCart, wishlist } = useSelector((state) => state.product);

  useEffect(() => {
    filterProducts();
  }, [wishlist, selectedCategory, search]);

  const handleAddToCart = (product) => {
    dispatch(addProductToCart({ product, quantity: 1, variation: {} }));
  };

  const handleRemoveFromWishlist = (product) => {
    dispatch(removeProductFromWishlist({ productId: product.id }));
  };

  const hideSidebar = () => {
    setIsSidebarVisible(false);
    document.querySelector('body').style.overflow = 'auto';
  };

  const showSidebar = () => {
    setIsSidebarVisible(true);
    document.querySelector('body').style.overflow = 'hidden';
  };

  const isProductInCart = (product) => productsInCart?.some(item => item.product.id === product.id);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const filterProducts = () => {
    let filtered = wishlist;

    if (selectedCategory !== 'All Categories') {
      filtered = filtered.filter(product => product.categories.includes(selectedCategory));
    }

    if (search) {
      filtered = filtered.filter(product => product.title.toLowerCase().includes(search.toLowerCase()));
    }

    setFilteredProducts(filtered);
  };

  

  // Generate categories from wishlist products
  const categories = useMemo(() => {
    const categoryMap = {};
    wishlist?.forEach(product => {
      product?.categories?.forEach(category => {
        if (!categoryMap[category]) {
          categoryMap[category] = 0;
        }
        categoryMap[category]++;
      });
    });
    return Object.entries(categoryMap).map(([name, quantity]) => ({ name, quantity, isActive: name === selectedCategory }));
  }, [wishlist, selectedCategory]);

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Favorite Products</Title>
      <Navigation>
        <InputWithLabel
          height={'38px'}
          value={search}
          onChange={handleSearchChange}
          placeholder={'Enter what you are looking for...'}
          borderColor={'#7CCC67'}
        />
        {/* <ControlsSecond>
          <SortBy>Sort by:</SortBy>
          <Dropdown name={'Default'} />
        </ControlsSecond> */}
        <ButtonMobile>
          <SvgIcon name='sort' />
          <p>Sort</p>
        </ButtonMobile>
      </Navigation>
      <Categories>
        {categories?.map((category, i) => (
          <Category key={i} isActive={category.isActive} onClick={() => handleCategoryClick(category.name)}>
            {category.name} ({category.quantity})
          </Category>
        ))}
      </Categories>
      <ProductsBox>
        <Products>
          {filteredProducts?.map((product, i) => (
            <Product key={i}>
              <ProductPhoto>
                <Photo src={product.image?.url} alt={product?.title} />
              </ProductPhoto>
              <ProductBox>
                <ProductTitle>{product?.title}</ProductTitle>
                <ProductInfo>
                  {product?.categories?.join(', ')}
                  <ProductRating>
                    <Img src={star} alt='star' />
                    <ImgMobile src={starMobile} alt='star' />
                    <ProductRatingTitle>{product?.rating}</ProductRatingTitle>
                    <RatingCount>({product?.totalReviews})</RatingCount>
                  </ProductRating>
                </ProductInfo>
                <ProductDescription>{product.description?.substring(0, 80)}</ProductDescription>
                <Box>
                  <ProductPrice>{formatMoney(product.price?.normalPrice?.amount)}</ProductPrice>
                  <ProductButtons>
                    {product?.availability?.status ? (
                      <>
                        {isProductInCart(product) ? 
                          <Link to={'/checkout'}>
                           <GlobalButton type={'green'}>
                            <Span>Checkout</Span>
                            </GlobalButton>
                          </Link> :
                            <GlobalButton type={'green'} onClick={() => handleAddToCart(product)}>
                            <SvgIcon name='cart' />
                            <Span>Add to cart</Span>
                          </GlobalButton>
                        }
                      </>
                    ) : (
                      <ViewButton>
                        <GlobalButton type={'secondary'} isActive={product?.availability?.status}>
                          <View />
                          View Similar
                        </GlobalButton>
                      </ViewButton>
                    )}
                    {product?.availability?.status && (
                      <GlobalButton type={'orange-secondary'}>
                        <SvgIcon name='wallet' />
                        Buy Now
                      </GlobalButton>
                    )}
                    <DeleteButton onClick={() => handleRemoveFromWishlist(product)}>
                      <DeleteIco />
                    </DeleteButton>
                  </ProductButtons>
                </Box>
                {product?.availability?.status ? (
                  <ProductStatus isActive={product?.availability?.status}>Available</ProductStatus>
                ) : (
                  <ProductStatus isActive={product?.availability?.status}>Not Available</ProductStatus>
                )}
              </ProductBox>
            </Product>
          ))}

          {filteredProducts.length === 0 && (
                <Main>You have not liked any products yet.</Main>
            )}
        </Products>
      </ProductsBox>
    </>
  );
};