import React, { useEffect, useRef, useState } from 'react';

import Input from '../input/input';
import HeaderTopSection from './components/header-top-section/header-top-section';
import { Link } from 'react-router-dom';
import logo from '../../assets/ezimall_logo.svg';
import { SvgIcon } from '../svg-icon/svg-icon';
import { CartPopup } from './components/cart-popup/cart-popup';
import { StoreFinderBtn } from './components/store-finder-btn/store-finder-btn';
import { StoreFinderInput } from './components/store-finder-input/store-finder-input';
import {
    BurgerIconWrapper,
    CartButton,
    CartPopupWrapper,
    Container,
    Counter,
    HeaderStyled,
    HeaderWrapper,
    Inner,
    InputWrapper,
    LoginRegisterButton,
    Logo,
    LogoutBtn,
    StoreLocatorMobile
} from './header-styled';
import { ShopByCategoryMenu } from './components/shop-by-category-menu/shop-by-category-menu';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Categories from '../input/components/categories/categories';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/auth/authSlice';
import { getCattegoryMenu } from '../../redux/slices/products/categoriesSlice';
import axios from 'axios';
import { searchProducts } from './../../redux/slices/Search/searchSlice';
import { getRouteById } from 'routes/routesHelper';
import { FavouritePopup } from './components/favourite-popup/favourite-popup';

const Header = ({ isLoggedIn = false, isShopFinder = false, onStoreFinderSearch }) => {
    const suggestions = [];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const isAuth = auth.isAuth;

    const handleLogout = () => {
        dispatch(logout(auth));
    };

    const [search, setSearch] = React.useState(location.state?.term || '');
    const [isCartOpened, setIsCartOpened] = useState(false);
    const [isFavouriteOpened, setIsFavouriteOpened] = useState(false);
    const [isShopByCategoryMenuOpened, setIsShopByCategoryMenuOpened] = useState(false);
    const [isCategoryDropdownOpened, setIsCategoryDropdownOpened] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const ref = useRef();
    const dropdownRef = useRef(null);
    const { productsInCart, wishlist } = useSelector((state) => state.product);
    const { searchedProducts } = useSelector((state) => state.search);
    useEffect(() => {
        dispatch(getCattegoryMenu());

        if (isShopByCategoryMenuOpened) {
            disableBodyScroll(ref.current);
        } else {
            enableBodyScroll(ref.current);
        }

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsCategoryDropdownOpened(false);
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            clearAllBodyScrollLocks();
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isShopByCategoryMenuOpened]);

    const closeShopByCategoryMenu = () => {
        setIsShopByCategoryMenuOpened(false);
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setIsCategoryDropdownOpened(false);
    };

    const handleSearch = async (term) => {
        if (!term.trim()) return;

        const currentPath = window.location.pathname;

        const newState = {
            term,
            category: selectedCategory,
            loading: true
        };

        const searchParams = new URLSearchParams();
        searchParams.set('s', term);

        if (currentPath === '/categories-motor-vehicles-generic-search') {
            navigate(`${currentPath}?${searchParams.toString()}`, {
                replace: true,
                state: newState
            });
        } else {
            navigate(`/categories-motor-vehicles-generic-search?${searchParams.toString()}`, {
                state: newState
            });
        }
    };
    return (
        <>
            <HeaderWrapper>
                <HeaderTopSection />
                <HeaderStyled>
                    <Container>
                        <Inner>
                            <Link to={'/'} className={'logo-wrapper'}>
                                <Logo src={logo} alt='everything zimbabwean logo'></Logo>
                            </Link>
                            <div
                                className={`category-dropdown ${
                                    isShopByCategoryMenuOpened && 'opened'
                                }`}
                                onClick={() => {
                                    setIsShopByCategoryMenuOpened(!isShopByCategoryMenuOpened);
                                }}
                            >
                                <BurgerIconWrapper>
                                    {isShopByCategoryMenuOpened ? (
                                        <svg
                                            width='18'
                                            height='18'
                                            viewBox='0 0 18 18'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M15 2.5L3 14.5M3 2.5L15 14.5'
                                                stroke='#388638'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                        </svg>
                                    ) : (
                                        <SvgIcon name={'burger'} />
                                    )}
                                </BurgerIconWrapper>
                                <p>Shop by Category</p>
                            </div>
                            <InputWrapper ref={dropdownRef}>
                                {isShopFinder ? (
                                    <StoreFinderInput onSubmit={onStoreFinderSearch} />
                                ) : (
                                    <Input
                                        height={'40px'}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        dropdownOnClick={() =>
                                            setIsCategoryDropdownOpened(!isCategoryDropdownOpened)
                                        }
                                        placeholder={'Search...'}
                                        borderColor={'#F76D21'}
                                        suggestions={suggestions}
                                        selectedCategory={selectedCategory}
                                        onSearch={handleSearch}
                                        setIsDropdownOpen={setIsDropdownOpen}
                                        isDropdownOpen={isDropdownOpen}
                                    />
                                )}
                                <Categories
                                    isOpen={isCategoryDropdownOpened}
                                    onCategorySelect={handleCategorySelect}
                                />
                            </InputWrapper>
                            <div className={`preferences ${isAuth && 'preferences--loggedin'}`}>
                                <div className='preferences__item'>
                                    <StoreLocatorMobile>
                                        <StoreFinderBtn />
                                    </StoreLocatorMobile>
                                </div>
                                {isAuth && (
                                    <>
                                        <Link
                                            to='#'
                                            className='preferences__item preferences__item--account'
                                            onClick={handleLogout}
                                        >
                                            <SvgIcon name={'account'} />
                                        </Link>
                                        <div className='preferences__item'>
                                            <CartButton
                                                className='preferences__item'
                                                onClick={() => {
                                                    setIsFavouriteOpened(!isFavouriteOpened);
                                                }}
                                            >
                                                <SvgIcon name={'heart-2'} />
                                                {wishlist?.length > 0 && (
                                                    <Counter>{wishlist?.length}</Counter>
                                                )}
                                            </CartButton>
                                        </div>
                                    </>
                                )}

                                <div className='preferences__item'>
                                    <CartButton
                                        className='preferences__item'
                                        onClick={() => {
                                            setIsCartOpened(!isCartOpened);
                                        }}
                                    >
                                        <SvgIcon name={'cart'} />
                                        {productsInCart?.length > 0 && (
                                            <Counter>{productsInCart.length}</Counter>
                                        )}
                                    </CartButton>
                                </div>
                            </div>
                            {!isAuth && (
                                <LoginRegisterButton>
                                    <Link to={'/login'}>Log In</Link>
                                    <div />
                                    <Link to={'/register'}>Register</Link>
                                </LoginRegisterButton>
                            )}
                        </Inner>
                    </Container>
                </HeaderStyled>
                <CartPopupWrapper isCartOpened={isCartOpened}>
                    <CartPopup
                        isOpened={isCartOpened}
                        onCloseBtnClick={() => {
                            setIsCartOpened(false);
                        }}
                    />
                </CartPopupWrapper>
                <CartPopupWrapper isCartOpened={isFavouriteOpened}>
                    <FavouritePopup
                        isOpened={isFavouriteOpened}
                        onCloseBtnClick={() => {
                            setIsFavouriteOpened(false);
                        }}
                    />
                </CartPopupWrapper>
            </HeaderWrapper>
            <ShopByCategoryMenu
                isOpened={isShopByCategoryMenuOpened}
                ref={ref}
                onClose={closeShopByCategoryMenu}
            />
        </>
    );
};

export default Header;
