import { Buttons, CartPopupBlockStyled, ProductsList, Summary } from './cart-popup-block-styled';

import { BusinessLinkCard } from 'pages/cart/components/business-link-card/business-link-card';
import { ProductItem } from 'pages/cart/components/product-item/product-item';
import { PromocodeInput } from 'pages/cart/components/promocode-input/promocode-input';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from '../../../../../../redux/slices/product/productSlice';
import { formatMoney } from 'utils';
import { Link } from 'react-router-dom';

export const CartPopupBlock = () => {
  const dispatch = useDispatch();
  const { productsInCart } = useSelector((state) => state.product);

  const handleClearCart = () => {
    dispatch(clearCart());
  }

  
  const totalValue = productsInCart?.reduce((total, item) => {
    const itemTotal = item.quantity * item.product.price?.normalPrice?.amount;
    return total + itemTotal;
  }, 0);

  return (
    <CartPopupBlockStyled>
      <BusinessLinkCard />
      <ProductsList>
        {productsInCart?.map((item, i) => {
          return <ProductItem item={item} key={i} />;
        })}
      </ProductsList>
      {
        productsInCart?.length > 0 && <>
          <PromocodeInput />
          <Summary>
        <span>Summary</span>
        <span>{formatMoney(totalValue)}</span>
        </Summary>
        <Buttons>
          <GlobalButton type={'primary'}>
            <SvgIcon name={'receipt'} />
            Proceed to Checkout
          </GlobalButton>
          <Link to={'/cart'}>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'cart'} />
              Go to Cart
            </GlobalButton>
          </Link>    
        </Buttons>
        </>
      }
     
      
      {productsInCart?.length > 0 && <p style={{ marginTop: 30, color: 'red', cursor: 'pointer'}} onClick={handleClearCart}>Clear Cart</p>}
   
    </CartPopupBlockStyled>
  );
};
