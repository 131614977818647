import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import { Link } from 'react-router-dom';  // Import Link component
import {
  Container,
  KnowledgeBox,
  Photo,
  Block,
  Title,
  Description,
  ButtonBlock
} from './knowledge-base-info-styled';
import img1 from '../../assets/article.png';
import img2 from '../../assets/faqs.webp';
import img3 from '../../assets/happy-young-african.webp';

const singles = [
  {
    img: img1,
    title: 'News & Updates',
    description:
      'Stay informed about the newest products, services, and company developments as well as industry insights and trends.',
    titleLink: 'See All',
    link: '/blog'  // Updated the link format to be relative for routing
  },
  {
    img: img2,
    title: 'FAQs',
    description:
      'Get quick and easy answers to some of the most common questions about our products, services, and company.',
    titleLink: 'See All',
    link: ''  // Updated the link format to be relative for routing
  },
  {
    img: img3,
    title: 'Video Tutorials',
    description:
      'Get helpful & informative videos & tutorials here that will take you step by step through the features and benefits of what we have to offer.',
    titleLink: 'See All',
    link: ''  // Updated the link format to be relative for routing
  }
];

export const KnowledgeBaseInfo = () => {
  return (
    <Container>
      {singles.map((single, index) => (
        <KnowledgeBox key={index}>
          <Photo src={single.img} alt={single.title} />
          <Block>
            <Title>{single.title}</Title>
            <Description>{single.description}</Description>
            <ButtonBlock>
              {/* Wrap GlobalButton in a Link to navigate */}
              <Link to={single.link}>
                <GlobalButton height={40} type={'green'} fullWidth>
                  <SvgIcon name='eye' />
                  {single.titleLink}
                </GlobalButton>
              </Link>
            </ButtonBlock>
          </Block>
        </KnowledgeBox>
      ))}
    </Container>
  );
};
