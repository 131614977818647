import {
  Container,
  ImgWrapper,
  OldPrice,
  Price,
  MainPrice,
  Title,
  MainBox,
  Main,
  Label,
  RateStarsDesktop,
  Content,
  ContactsBox,
  Buttons,
  FavBtn4,
  LabelNotAvailable,
  BuyButton,
  Description
} from './p-card-small-styled';

import { SvgIcon } from '../svg-icon/svg-icon';

import { RateStars } from '../rate-stars/rate-stars';
import { LabelInStock, LabelOutStock, ZWLPrice } from '../p-card/p-card-styled';
import { ApproveSeller } from '../approve-seller/approve-seller';
import React, { useState } from 'react';
import { getRouteById } from 'routes/routesHelper';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToCart, addProductToWishlist, removeProductFromWishlist } from '../../redux/slices/product/productSlice';
import { Button } from 'components/categories-card/categories-card-styled';

export const PCardSmall = ({ vendor, product, className }) => {
  const { productsInCart, wishlist } = useSelector((state) => state.product);

  const dispatch = useDispatch();

   const productUrl = getRouteById('product', { slug: product.slug }); 

   const handleAddToCart = () => {
      dispatch(addProductToCart({ product, quantity: 1, variation: {} }));
   };
  
    const handleAddToWishlist = () => {
        dispatch(addProductToWishlist({ product }));
    };
  
     const handleRemoveFromWishlist = () => {
       dispatch(removeProductFromWishlist({ productId: product.id }));
    };
    
  const isProductInCart = productsInCart?.some(item => item.product?.id === product?.id);
  const isProductInWishlist = wishlist?.some(item => item.id === product.id);

  return (
    <Container className={className}>
        <ImgWrapper>
          <ApproveSeller />
          <img src={product?.image?.url} alt={product.title} />
          <FavBtn4 onClick={isProductInWishlist ? handleRemoveFromWishlist : handleAddToWishlist} isFav={isProductInWishlist}>
              <SvgIcon name={'heart-2'} />
          </FavBtn4>
          {product?.availability?.status ? (
            <LabelInStock>Stock</LabelInStock>
          ) : (
            <LabelOutStock>Out stock</LabelOutStock>
          )}
          {product.delivery ? (
            <Label>Delivery Available</Label>
          ) : (
            <LabelNotAvailable>Delivery not available</LabelNotAvailable>
          )}
          </ImgWrapper>
      <Main>
        <MainBox>
          <RateStarsDesktop>
            {product.rating && (
              <RateStars rate={product.rating} reviews={product.totalReviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Link to={productUrl}>
          <Title>{product.title}</Title>
          <Description>{vendor}</Description>
        </Link>
        <Content>
          <Price>
            <OldPrice>{product?.price.normalPrice?.[0]?.currency} {product?.price.normalPrice?.[0]?.amount}</OldPrice>
            <MainPrice>{product?.price.normalPrice?.[0]?.currency} {product?.price.normalPrice?.[0]?.amount}</MainPrice>
            {/* <ZWLPrice>ZWL 200</ZWLPrice> */}
          </Price>
           <Buttons>
                {isProductInCart ? (
                    <Link to="/cart">
                          <Button>
                            View
                        </Button>
                    </Link>
                ) : (
                    <BuyButton onClick={handleAddToCart}>
                        <SvgIcon name='cart' />
                    </BuyButton>
                )}
            </Buttons>
        </Content>
      </Main>
    </Container>
  );
};
