import Header from '../../components/header/header';
import {
  BlogStyled,
  Container,
  Content,
  Main,
  Menu,
  MenuItem,
  MenuSection,
  MenuTitle,
  Sidebar
} from './blog-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Featured } from './components/featured/featured';
import { ReactComponent as FaqIcon } from './icons/faq-icon.svg';
import { ReactComponent as HelpIcon } from './icons/help-icon.svg';
import { ReactComponent as NewsIcon } from './icons/news-icon.svg';
import { ReactComponent as RecentUpdatesIcon } from './icons/recent-updates-icon.svg';
import { ReactComponent as TutorialsIcon } from './icons/tutorials-icon.svg';
import { LatestVideos } from './components/latest-videos/latest-videos';
import { RecentUpdates } from './components/recent-updates/recent-updates';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getKnowledgeBaseArticles } from '../../redux/slices/knowledgeBase/knowledgeBaseSlice';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Blog' }
];

export const Blog = () => {

  const dispatch = useDispatch();

  const [articles, setArticles] = useState([true]);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  
  const slug = 'news-updates';
  
  const params = {
    slug:slug 
  };

  useEffect(() => {
    dispatch(getKnowledgeBaseArticles(params))
      .then((result) => {
        console.log('Fetched articles:', result); 
        setArticles(result.payload?.data?.load);
        setStatus("succeeded");  
      })
      .catch((error) => {
        console.error('Failed to fetch articles:', error);
        setStatus("error");
      });
  }, [dispatch]);

  return (
    <BlogStyled>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        {/* <Featured /> */}
        <Main>
          <Sidebar>
            <MenuSection>
              <MenuTitle>
                <NewsIcon />
                 Knowledge Base
              </MenuTitle>
              <Menu>
              <MenuItem>
              <Link to="/blog"> {/* Add your desired link path here */}
              <RecentUpdatesIcon />
              Latest News Updates
              </Link>
              </MenuItem>
              </Menu>
            </MenuSection>
            <MenuSection>
              <MenuTitle>
                <HelpIcon />
                Help Center
              </MenuTitle>
              <Menu>
                <MenuItem>
                  <FaqIcon />
                  Recent Updates
                </MenuItem>
                <MenuItem>
                  <TutorialsIcon />
                  Tutorials
                </MenuItem>
                <MenuItem>
                  <TutorialsIcon />
                   Faqs
                </MenuItem>
                <MenuItem>
                  <TutorialsIcon />
                   Contact Us
                </MenuItem>
              </Menu>
            </MenuSection>
          </Sidebar>
          <Content>
             {/* <LatestVideos />*/ }
            <RecentUpdates articles={articles} />
          </Content>
        </Main>
      </Container>
    </BlogStyled>
  );
};
