import React from 'react'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { get, remove, update } from 'lodash';
import localStorage from 'redux-persist/es/storage';
import { getToken } from 'utils';

const productsURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_PRODUCTS_API_URL;
const apiBaseUrl = process.env.REACT_APP_API_BASE_ENDPOINT
const categoryProductsURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_CATEGORY_PRODUCTS_API_URL;
const createProductURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_CREATE_PRODUCT_API_URL;



const initialState = {
    products: [],
    status: 'idle',
    error: null,
    message: '',
    deletingProduct: false,
    productsCategory: [],
    productsInCart: [],
    wishlist: [],
};



// items = [
//     {
//         product: product,
//         quantity: 1,
//         variation: {
//             "key": "value",
//         }
//     }
// ]



export const getProducts = createAsyncThunk(
    'products/getBusinessProducts',
    async (params, { rejectWithValue }) => {
        const { bID } = params
        try {
            const response = await axios.post(productsURL,

                {
                    bID
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response?.data?.message);
        }
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (params, { rejectWithValue }) => {
        const { bID, id } = params;
        try {
            const response = await axios.post(`${apiBaseUrl}/product/delete`,
                {
                    id,
                    bID
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response?.data?.message);
        }
    }
);

export const getProductsBySlug = createAsyncThunk(
    'products/getProductBySlug',
    async (params, { rejectWithValue }) => {
        const { slug } = params;
        try {

            const response = await axios.post(`${apiBaseUrl}/product?slug=${slug}`, {

                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log("response", response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            const errorMessage = err.response?.data?.message || 'An error occurred';
            return rejectWithValue(errorMessage);
        }
    }
);

export const getRelatedProducts = createAsyncThunk(
    'products/getRelatedProducts',
    async (params, { rejectWithValue }) => {
        const { slug, limit } = params;
        try {
            const response = await axios.get(`${apiBaseUrl}/products/related?slug=${slug}&limit=${limit}`, {
                headers: {
                    'Content-Type': 'application/json',
                },

            });

            return response.data;
        } catch (err) {
            console.log(err);
            const errorMessage = err.response?.data?.message || 'An error occurred';
            return rejectWithValue(errorMessage);
        }
    }
);

export const getProductsByCategory = createAsyncThunk(
    'products/getProductsByCategory',
    async (params, { rejectWithValue }) => {
        const { slug } = params;
        try {
            const response = await axios.get(`${categoryProductsURL}?slug=${slug}`,

                {

                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

            return response.data;
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'An error occurred';
            return rejectWithValue(errorMessage);
        }
    }
);


export const createProduct = createAsyncThunk(


    'products/createProduct',
    async (productData, { rejectWithValue }) => {
        try {

            const token = getToken();


            const response = await axios.post(createProductURL, productData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (err) {
            if (err.response?.status === 401) {
                console.log('Unauthorized. Redirect to login.')
            }
            console.log(err);
            return rejectWithValue(err.response?.data?.message);
        }
    }
)



const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        addProductToCart(state, action) {
            const { product, quantity = 1, variation = {} } = action.payload;
            const existingItem = state.productsInCart?.find(item => item.product.id === product.id && JSON.stringify(item.variation) === JSON.stringify(variation));
            if (existingItem) {
                existingItem.quantity += quantity;
            } else {
                state.productsInCart?.push({ product, quantity, variation });
            }
        },
        removeProductFromCart(state, action) {
            const { productId, variation = {} } = action.payload;
            state.productsInCart = state.productsInCart?.filter(item => item.product.id !== productId || JSON.stringify(item.variation) !== JSON.stringify(variation));
        },
        updateProductInCart(state, action) {
            const { productId, quantity, variation } = action.payload;
            const existingItem = state.productsInCart?.find(item => item.product.id === productId && JSON.stringify(item.variation) === JSON.stringify(variation));
            if (existingItem) {
                existingItem.quantity = quantity;
                existingItem.variation = variation;
            }
        },
         clearCart(state) {
            state.productsInCart = [];
        },
        addProductToWishlist(state, action) {
            const { product } = action.payload;
    
            if (!state.wishlist) {
                state.wishlist = [];
            }
            const existingItem = state.wishlist.find(item => item.id === product.id);
            if (!existingItem) {
                state.wishlist.push(product);
            }
        },
        removeProductFromWishlist(state, action) {
            const { productId } = action.payload;
          
            if (!state.wishlist) {
                state.wishlist = [];
            }
            state.wishlist = state.wishlist.filter(item => item.id !== productId);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                // Ensure the payload is an array
                state.products = Array.isArray(action.payload?.data) ? action.payload.data : [];
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.payload;
                state.products = [];
            })
            // .addCase(getProductsBySlug.pending, (state) => {
            //     state.status = 'loading';
            //     state.error = null;
            // })

            // .addCase(getProductsBySlug.fulfilled, (state, action) => {
            //     state.status = 'success';
            //     state.error = null;
            //     state.productsCategory = action.payload;
            // })


            // .addCase(getProductsBySlug.rejected, (state, action) => {
            //     state.status = 'rejected';
            //     state.error = action.payload;
            // })
            .addCase(getProductsByCategory.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })

            .addCase(getProductsByCategory.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                state.productsCategory = action.payload;
            })


            .addCase(getProductsByCategory.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.payload;
            })

            .addCase(getRelatedProducts.pending, (state, action) => {
                state.status = 'loading_related';
                state.error = null;
            })

            .addCase(getRelatedProducts.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
            })

            .addCase(getRelatedProducts.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.payload;
            })


            .addCase(createProduct.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                // Ensure state.products is an array before spreading

            })
            .addCase(createProduct.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.payload;
            })

            .addCase(deleteProduct.pending, (state) => {
                state.deletingProduct = true
                state.message = '';
                state.error = null;
            })

            .addCase(deleteProduct.fulfilled, (state) => {
                state.deletingProduct = false
                state.error = null;
                state.message = action.payload.data.message

            })

            .addCase(deleteProduct.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.payload.data.message
            })
    },
});


export const { addProductToWishlist, removeProductFromWishlist, addProductToCart, removeProductFromCart, updateProductInCart, clearCart } = productSlice.actions;

export default productSlice.reducer;