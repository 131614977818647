import { useState, useEffect } from 'react';
import { ReactComponent as CloseIcon } from './img/close-icon.svg';
import { ReactComponent as MinusIcon } from './img/minus.svg';
import { ReactComponent as PlusIcon } from './img/plus.svg';
import {
  Container,
  Counter,
  DeleteBtn,
  Description,
  DescriptionItem,
  ImgWrapper,
  Main,
  Price,
  PriceMobile,
  Subtitle,
  Title
} from './product-item-styled';
import { removeProductFromCart, updateProductInCart } from '../../../../redux/slices/product/productSlice';
import { useDispatch } from 'react-redux';
import { formatMoney } from 'utils';

export const ProductItem = ({ item }) => {
  const [counter, setCouter] = useState(item?.quantity);
  const [variation, setVariation] = useState(item?.variation || {});
  const dispatch = useDispatch();

  const removeFromCart = () => {
    dispatch(removeProductFromCart({ productId: item.product?.id, variation: variation }));
  };

  useEffect(() => {
    dispatch(updateProductInCart({ productId: item.product?.id, quantity: counter, variation }));
  }, [counter, variation, dispatch, item.product?.id]);


  return (
    <Container>
      <ImgWrapper>
        <img src={item.product?.image?.url} alt={item.product?.title} />
      </ImgWrapper>
      <Main>
        <Title>{item.product?.title}</Title>
        <Subtitle>{item.product?.description?.substring(0, 60)}..</Subtitle>
        <Description>
           {variation && Object.entries(variation).map(([key, value]) => (
             <DescriptionItem key={key}>{`${key}: ${value}`}</DescriptionItem>
           ))}
        </Description>
      </Main>
      <Counter>
        <PriceMobile>{formatMoney(item.product?.price?.normalPrice?.amount)}</PriceMobile>
        <div>
          <button
            onClick={() => {
              setCouter(counter - 1);
            }}
          >
            <MinusIcon />
          </button>
          <input
            value={counter}
            onChange={(e) => {
              setCouter(+e.target.value);
            }}
          />
          <button
            onClick={() => {
              setCouter(counter + 1);
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </Counter>
      <Price>{formatMoney(item.product?.price?.normalPrice?.amount)}</Price>
      <DeleteBtn onClick={removeFromCart}>
        <span> Delete</span>
        <CloseIcon />
      </DeleteBtn>
    </Container>
  );
};
