import { Buttons, CartPopupBlockStyled, ProductsList, Summary } from '../../../../components/cart-popup/components/cart-popup-block/cart-popup-block-styled';

import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, addProductToCart, addProductToWishlist, removeProductFromWishlist } from '../../../../../../redux/slices/product/productSlice';
import { Link } from 'react-router-dom';
import {
    Box,
    DeleteButton,
    Img,
    ImgMobile,
    Photo,
    Product,
    ProductBox,
    ProductButtons,
    ProductInfo,
    ProductPhoto,
    ProductPrice,
    ProductRating,
    ProductRatingTitle,
    Products,
    ProductsBox,
    ProductStatus,
    ProductTitle,
    RatingCount,
    Span,
    ViewButton
} from '../../../../../../pages/profile/pages/favorite-products/favorite-products-styled';

import star from '../../../../../../assets/icons/star.svg';
import starMobile from '../../../../../../assets/icons/star-ico.svg';
import { ReactComponent as View } from '../../../../../../assets/icons/view.svg';
import { ReactComponent as DeleteIco } from '../../../../../../assets/icons/delete-ico.svg';
import { formatMoney } from 'utils';

export const FavouritePopupBlock = () => {
  const dispatch = useDispatch();
    const { productsInCart, wishlist } = useSelector((state) => state.product);
    
    const isProductInCart = (product) => productsInCart?.some(item => item.product?.id === product?.id);
    const isProductInWishlist = (product) => wishlist?.some(item => item.id === product.id);

  const handleClearCart = () => {
    dispatch(clearCart());
  }
    
    
    const handleAddToCart = (product) => {
        dispatch(addProductToCart({ product, quantity: 1, variation: {} }));
    }

    const handleRemoveFromWishlist = (product) => {
        dispatch(removeProductFromWishlist({ productId: product.id }));
    }

  


  return (
    <CartPopupBlockStyled>
          <ProductsBox>
          <Products>
            {wishlist?.map((product, i) => (
              <Product key={i}>
                <ProductPhoto>
                  <Photo src={product.image?.url} alt={product?.title} />
                </ProductPhoto>
                <ProductBox>
                        <ProductTitle style={{fontSize: 12}}>{product?.title}</ProductTitle>
                  <ProductInfo>
                    {product?.business.name}
                    <ProductRating>
                      <Img src={star} alt='star' />
                      <ImgMobile src={starMobile} alt='star' />
                      <ProductRatingTitle>{product?.rating}</ProductRatingTitle>
                      <RatingCount>({product?.totalReviews})</RatingCount>
                    </ProductRating>
                  </ProductInfo>
                  
                  <Box>
                    <ProductPrice>{formatMoney(product.price?.normalPrice?.amount)}</ProductPrice>
                    <ProductButtons>
                      {product?.availability?.status ? (
                        <>
                          {isProductInCart(product) ? 
                            <Link to={'/checkout'}>
                             <GlobalButton type={'green'}>
                              <Span>Checkout</Span>
                              </GlobalButton>
                            </Link> :
                              <GlobalButton type={'green'} onClick={() => handleAddToCart(product)}>
                                Buy Now
                             </GlobalButton>
                          }
                        </>
                      ) : (
                        <ViewButton>
                          <GlobalButton type={'secondary'} isActive={product?.availability?.status}>
                            <View />
                            View Similar
                          </GlobalButton>
                        </ViewButton>
                      )}
                      
                      <DeleteButton onClick={() => handleRemoveFromWishlist(product)}>
                        <DeleteIco />
                      </DeleteButton>
                    </ProductButtons>
                  </Box>
                  {product?.availability?.status ? (
                    <ProductStatus isActive={product?.availability?.status}>Available</ProductStatus>
                  ) : (
                    <ProductStatus isActive={product?.availability?.status}>Not Available</ProductStatus>
                  )}
                </ProductBox>
              </Product>
            ))}
          </Products>
          </ProductsBox>
          <Buttons>
          <Link to={'/account/favourite'}>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'cart'} />
                Go to Favourites
            </GlobalButton>
          </Link>    
        </Buttons>
    </CartPopupBlockStyled>
  );
};
